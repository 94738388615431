import { useState } from 'react';
import { useNavigate } from 'react-router';
import { auth } from '../../firebaseConfig';
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { Button, Callout, Card, Icon, InputGroup } from '@blueprintjs/core';

export default function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function login(e) {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        console.log("Logged in as ", user.email);
        setError();
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
        setError("Error logging in. Please check your credentials and try again.")
      });
  }

  return <center>
    <br /><br /><br />
    <Card style={{ width: 300 }}>
      <div style={styles.titleGroup} onClick={() => navigate("/")} >
        <Icon icon="grid-view" color="lightgrey" size={18} style={styles.logo} />
        <div style={styles.title}>STITCH PORTAL</div>
      </div>
      <br /><br />
      {auth.currentUser ?
        <div>
          <Button onClick={() => signOut(auth)} >Logout</Button>
        </div> :
        <form>
          {error && <Callout intent="danger" style={styles.input}>{error}</Callout>}
          <InputGroup style={styles.input} placeholder="Email" type="text"
            value={email} onChange={(event) => setEmail(event.target.value)} />
          <InputGroup style={styles.input} placeholder="Password" type="password"
            value={password} onChange={(event) => setPassword(event.target.value)} />
          <br />
          <Button type="submit" onClick={(e) => login(e)} >Login</Button>
          &nbsp;&nbsp;&nbsp;
          <Button onClick={() => navigate("/")}>Cancel</Button>
        </form>}
    </Card>
    <div style={styles.footer}>~ Cloudberry Apps ~</div>
  </center>
}

const styles = {
  input: {
    margin: 10,
    width: 240
  },
  title: {
    cursor: 'pointer',
    fontSize: 22,
    fontFamily: 'sans-serif',
    color: 'grey',
    fontWeight: 322,
    fontStretch: 'condensed'
  },
  logo: {
    margin: 4,
    padding: 0
  },
  titleGroup: {
    align: 'center',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  footer: {
    position: 'fixed',
    bottom: 4,
    width: '100%',
    textAlign: 'center',
    fontSize: 9,
    backgroundColor: "#f6f7f9"
  }
}
