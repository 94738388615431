import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { Button, Drawer, Icon, Menu, MenuDivider, MenuItem, NavbarHeading } from '@blueprintjs/core';
import { useGetUserQuery } from '../store/docApi';

export default function NavMenu({ showLogo }) {
  const { data: userinfo } = useGetUserQuery({}, { skip: !auth.currentUser });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  let user = auth.currentUser;

  function menuClicked(path) {
    setIsMenuOpen(false);
    setIsUserMenuOpen(false);
    navigate(path);
  }

  function logoutClicked() {
    setIsMenuOpen(false);
    setIsUserMenuOpen(false);
    signOut(auth).then(() => window.location.reload());
    navigate("/");
  }

  const userMenu = user && (
    <Menu>
      <MenuItem icon="person" text="Profile" onClick={() => menuClicked('/profile')} />
      <MenuItem icon="log-out" text="Logout" onClick={() => logoutClicked()} />
    </Menu>
  );

  const menu = user && (
    <Menu>
      <MenuItem icon="search" text="Search" onClick={() => menuClicked("/search")} />
      <MenuItem icon="path-search" text="Motifs" onClick={() => menuClicked("/motifs")} />
      <MenuItem icon="grid-view" text="Patterns" onClick={() => menuClicked("/patterns")} />
      <MenuItem icon="map" text="Projects" onClick={() => menuClicked("/projects")} />
      <MenuItem icon="calendar" text="Events" onClick={() => menuClicked("/events")} />
      <MenuItem icon="shop" text="Stores" onClick={() => menuClicked("/stores")} />
      <MenuItem icon="th-list" text="Orders" onClick={() => menuClicked("/orders")} />
      <MenuDivider />
      {userinfo && userinfo.name &&
        <MenuItem icon="user" text={`@${userinfo.name} Projects`} onClick={() => menuClicked(`/@${userinfo.name}`)} />}
      <MenuDivider />
      <MenuItem icon="layout-grid" text="Items" onClick={() => menuClicked("/item")} />
      <MenuItem icon="graph" text="Nodes" onClick={() => menuClicked("/node")} />
      <MenuDivider />
      <MenuItem icon="cube" text="New project" onClick={() => menuClicked(`@${userinfo.name}/create`)}
        selected={location.pathname.startsWith("/project/create")} />
      <MenuItem icon="snowflake" text="New motif" onClick={() => menuClicked("/motif/create")}
        selected={location.pathname.startsWith("/motif/create")} />
      <MenuItem icon="book" text="New collection" onClick={() => menuClicked("/collection/create")}
        selected={location.pathname.startsWith("/collection/create")} />
    </Menu>
  );

  return <div style={styles.bar}>
    <div style={{ display: 'flex' }}>
      {user && <Button icon="menu" minimal onClick={() => setIsMenuOpen(true)} />}
      {user && <Drawer position="left" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}
        canOutsideClickClose size="200px" title="Options">
        {menu}
      </Drawer>}
    </div>
    <div>
      <div style={styles.titleGroup} onClick={() => navigate("/")}>
        <Icon icon="grid-view" color="lightgrey" size={18} style={styles.logo} />
        <NavbarHeading style={styles.title}>STITCH PORTAL</NavbarHeading>
      </div>
    </div>
    <div>
      {!user && !location.pathname.startsWith("/login") && <Button name="Login" onClick={() => navigate('/login')}>Login</Button>}
      {user && userinfo && <div style={styles.usernameCircleStyle} onClick={() => setIsUserMenuOpen(true)}>
        {userinfo.name.slice(0, 2).toUpperCase()}</div>}
      {user && userinfo && <Drawer position="right" isOpen={isUserMenuOpen} onClose={() => setIsUserMenuOpen(false)}
        canOutsideClickClose size="200px" title={userinfo.name}>
        {userMenu}
      </Drawer>}
    </div>
  </div>
}

const styles = {
  bar: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #d9d9d9',
    alighItems: 'center',
    background: 'white',
    margin: 0,
    padding: 5,
    minWidth: '300px'
  },
  title: {
    fontSize: 22,
    fontFamily: 'sans-serif',
    color: 'grey',
    fontWeight: 322,
    fontStretch: 'condensed'
  },
  logo: {
    margin: 4,
    padding: 0
  },
  titleGroup: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  usernameCircleStyle: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    borderWidth: '0.5px',
    borderStyle: 'solid',
    color: 'grey',
    textTransform: 'uppercase',
    overflow: 'hidden',
    cursor: 'pointer'
  }
}