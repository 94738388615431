import { useState } from "react";
import { Link } from "react-router-dom";
import { Card } from '@blueprintjs/core';
import { useGetItemQuery, useGetRelatedQuery } from '../../store/docApi';
import { Colors } from "../../styles";
import { formatDate } from "../../utils";
import Links from "../Widgets/Links";
import Notes from "../Widgets/Notes";
import PartDetails from "../Parts/PartDetails";

export default function Instructions({ patternPk, patternId, projectId, stepId }) {
    const { data: item } = useGetItemQuery({ label: "pattern", pk: patternPk, id: patternId });
    const { data: datasubitems } = useGetRelatedQuery({ name: "isPartOf", value: `pattern/${patternPk}/${patternId}`, container: "pattern", pk: patternPk })
    // const { data: projects } = useGetRelatedQuery({ name: "isBasedOn", value: `howto/${pk}/${id}`, container: "user", pk: "usr" });
    // const { data: notes, refetch: refetchNotes } = useGetConnectedQuery({ _id: `${label}/${pk}/${id}`, container: "note", pk: "usr" });
    const { data: project } = useGetItemQuery({ "label": "project", "pk": "usr", "id": projectId }, { skip: !projectId })
    const [currentSubitem, setCurrentSubitem] = useState();
    const [currentStepId, setCurrentStepId] = useState();
    const [selectedStepId, setSelectedStepId] = useState(stepId);
    if (item) document.title = item.name;

    let subitems = [];
    if (datasubitems) {
        subitems = [...datasubitems]
        subitems.sort((p1, p2) => { return p1.index && p2.index ? p1.index - p2.index : 0 });
    }

    let palette = subitems ? subitems.find(s => s.label === "palette") : undefined;
    let instructions = subitems.find(s => s.label === "instructions");

    function getProgress(id) {
        if (project && project.progress)
            return project.progress[id];
        else
            return "";
    }

    function getStepBgColor(startdate, stepprogress) {
        let now = Date.now();
        let date = new Date(startdate);
        return now <= date ? 'whitesmoke' :
            stepprogress === 'started' ? Colors.ChartProgressStarted :
                stepprogress === 'done' ? Colors.ChartProgressDone : 'lavender';
    }

    function showSection(subitem, stepid) {
        setCurrentSubitem(subitem);
        setCurrentStepId(stepid);
    }

    function selectStep(stepid) {
        setSelectedStepId(stepid);
    }

    function PartCard(step, i, stepid) {
        let stepprogress = getProgress(stepid);
        let subitem = subitems.find(s => s.id === step.part_id);
        let cardname = step.name ? step.name : subitem && subitem.name ? subitem.name : "";
        let cardpicture = step.picture ? step.picture : subitem && subitem.picture ? subitem.picture : null;
        let cardyarn = step.yarn ? step.yarn : subitem && subitem.yarn ? subitem.yarn : null;
        //let palette = subitems.find(s => s.label === 'palette');
        let yarncolor = Colors.OffWhite;
        if (palette && palette.shades) {
            let shade = palette.shades.find(shade => shade.name === cardyarn);
            if (shade) yarncolor = shade.color;
        }
        let card = (
            <div key={i}>
                <Card key={i} interactive={!!subitem || step.verbal || step.notes} style={{ padding: 0, margin: 10 }}
                    onClick={() => subitem ? showSection(subitem, stepid) : selectStep(stepid)}>
                    <div style={{ ...styles.card,
                        backgroundColor: subitem ? getStepBgColor(step.startdate, stepprogress) : 'whitesmoke',
                        borderColor: selectedStepId === stepid ? 'orange' : 'lightgrey'}}>
                        <div style={styles.row}>
                            {cardyarn && <div style={{ backgroundColor: yarncolor, width: 10 }}></div>}
                            {cardpicture && <img style={styles.picture} src={cardpicture} alt={cardpicture} />}
                            <Notes notes={step.notes} />
                            <div style={styles.step}>
                                <b>
                                    {cardname}
                                    {step.repeat ? ' x ' : ''} {step.repeat}
                                </b>
                                {step.startdate && <div style={styles.stepdate}>{formatDate(step.startdate)}</div>}
                                {step.repeat && <div style={styles.repeat}>
                                    {Array(step.repeat).fill(0).map((r, j) => {
                                        let stepidR = `${stepid}_${j}`;
                                        let stepprogressR = getProgress(stepidR);
                                        return <Card key={j} style={{ ...styles.minicard, backgroundColor: subitem ? getStepBgColor(step.startdate, stepprogressR) : 'whitesmoke' }}>
                                            {j + 1}
                                        </Card>
                                    })}
                                </div>}
                                {step.verbal && <span>{step.verbal}</span>}
                                <div style={styles.stepid}>{stepid}</div>
                            </div>
                        </div>
                    </div>
                </Card>
                {currentSubitem && subitem && currentSubitem.id === subitem.id && currentStepId === stepid &&
                    <div>
                        <b>{currentSubitem.name}</b>
                        <PartDetails item={item} subitem={currentSubitem} subitems={subitems} project={project} palette={palette} depth={stepid} />
                    </div>}
            </div>
        );
        return card;
    }

    return <div>
        {item && (<div>
            {instructions && instructions.steps && <div>
                {instructions.steps.map((istep, i) => {
                    return istep.steps ?
                        <div key={i}>
                            {istep.title && <b>{istep.title}</b>}
                            {istep.name && <b>{istep.name}</b>}
                            {istep.verbal && <span>{istep.verbal}</span>}
                            {istep.startdate && <div>{formatDate(istep.startdate)}</div>}
                            <Notes notes={istep.notes} />
                            <div>
                                {istep.steps && istep.steps.map((jstep, j) => {
                                    return PartCard(jstep, j, `${instructions.id}_${i}_${j}`)
                                })}
                            </div>
                        </div>
                        :
                        <div key={i}>
                            {istep.title && <b>{istep.title}</b>}
                            {PartCard(istep, i, `${instructions.id}_${i}`)}
                        </div>
                })}
            </div>
            }
            <Links links={item.links} />
            <br />
            <ul>
                {
                    subitems.map((subitem) => {
                        return (
                            <li key={subitem.id}>
                                <Link onClick={() => showSection(subitem)}>
                                    {subitem.name && subitem.name} ({subitem.label && subitem.label}) {subitem.index && subitem.index}
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
        )}
    </div>
}

const styles = {
    row: {
        display: 'flex'
    },
    sections: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignSelf: 'stretch',
        justifyContent: "space-evenly"
    },
    id: {
        fontSize: 9
    },
    card: {
        marginHorizontal: 4,
        borderWidth: 1,
        borderStyle: 'solid'
    },
    repeat: {
        display: 'flex',
        flexDirection: 'row'
    },
    minicard: {
        margin: 5,
        padding: 10
    },
    step: {
        padding: 10,
    },
    stepid: {
        fontSize: 8,
        color: 'grey'
    },
    picture: {
        height: 100,
        //width: 100,
        margin: 2
    },
    text: {
        margin: 1
    }
};
